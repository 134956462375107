<template>
    <div>
        <b-row>
            <b-col md="6" lg="4" class="mb-3">
                <h4 class="mb-2">Background <tooltip-with-image img="/img/home/background.png"/></h4>
                <BackgroundSelector ref="BackgroundSelectorBackground" v-model="config.background"/>
            </b-col>
            <b-col md="6" lg="4" class="mb-3">
                <h4 class="mb-2">Cards <tooltip-with-image img="/img/home/cards.png"/></h4>
                <b-form-group>
                    <b-form-checkbox v-model="config.cards.card_shadows">Display card shadows</b-form-checkbox>
                </b-form-group>
                <b-form-group>
                    <b-form-checkbox v-model="config.cards.active_card_override">Change card on active</b-form-checkbox>
                </b-form-group>
                <b-form-group>
                    <label>Card type:</label>
                    <v-select
                        v-model="config.cards.card_type"
                        :reduce="item => item.id"
                        label="name"
                        :clearable="false"
                        :options="cardTypes"
                    />
                </b-form-group>
                <b-form-group v-if="config.cards.card_type === 0 || config.cards.card_type === 2">
                    <b-form-group>
                        <label>Card name color:</label>
                        <ColorWithOpacitySelector v-model="config.cards.normal.name"/>
                    </b-form-group>
                </b-form-group>
                <b-form-group v-if="config.cards.card_type === 0">
                    <label>Background type:</label>
                    <v-select
                        v-model="config.cards.normal.background_type"
                        :reduce="item => item.id"
                        label="name"
                        :clearable="false"
                        :options="backgroundTypes"
                    />
                </b-form-group>
                <b-form-group class="mt-3">
                    <h5 v-if="config.cards.normal.background_type === 0">Background</h5>
                    <h5 v-else>Icon Background</h5>
                    <BackgroundSelector ref="BackgroundSelectorCards" v-model="config.cards.normal.background"/>
                </b-form-group>
                <div v-if="config.cards.normal.background_type === 1">
                    <hr>
                    <b-form-group class="mt-3">
                        <h5>Text Background</h5>
                        <BackgroundSelector ref="BackgroundSelectorTextBackground" v-model="config.cards.normal.secondary_background"/>
                    </b-form-group>
                </div>
            </b-col>
            <b-col md="6" lg="4" class="mb-3" v-if="config.cards.active_card_override">
                <h4 class="mb-2">Active card <tooltip-with-image img="/img/home/active_card.png"/></h4>
                <b-form-group v-if="config.cards.card_type === 0 || config.cards.card_type === 2">
                    <b-form-group>
                        <label>Card name color:</label>
                        <ColorWithOpacitySelector v-model="config.cards.active.name"/>
                    </b-form-group>
                </b-form-group>
                <b-form-group v-if="config.cards.card_type === 0">
                    <label>Background type:</label>
                    <v-select
                        v-model="config.cards.active.background_type"
                        :reduce="item => item.id"
                        label="name"
                        :clearable="false"
                        :options="backgroundTypes"
                    />
                </b-form-group>
                <b-form-group class="mt-3">
                    <h5 v-if="config.cards.active.background_type === 0">Background</h5>
                    <h5 v-else>Icon Background</h5>
                    <BackgroundSelector ref="BackgroundSelectorActiveCards" v-model="config.cards.active.background"/>
                </b-form-group>
                <div v-if="config.cards.active.background_type === 1">
                    <hr>
                    <b-form-group class="mt-3">
                        <h5>Text Background</h5>
                        <BackgroundSelector ref="BackgroundSelectorActiveTextBackground" v-model="config.cards.active.secondary_background"/>
                    </b-form-group>
                </div>
            </b-col>
        </b-row>
        <hr/>
        <b-row>
            <b-col md="6" lg="4" class="mb-3" >
                <h4 class="mb-2">Cards lane <tooltip-with-image img="/img/home/cards_lane.png"/></h4>
                <b-form-group>
                    <label>Lane position from top:</label>
                    <b-form-input
                        type="range"
                        min="0"
                        max="1"
                        step="0.01"
                        v-model="config.cards.lane.position"
                    />
                </b-form-group>
                <b-form-group class="mt-3">
                    <h5>Background</h5>
                    <BackgroundSelector ref="BackgroundSelectorCardsLane" v-model="config.cards.lane.background"/>
                </b-form-group>

            </b-col>
        </b-row>
    </div>
</template>
<script>

    import BackgroundSelector from '@/views/components/BackgroundSelector'
    import { BCol, BFormCheckbox, BFormGroup, BFormInput, BRow } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import ColorWithOpacitySelector from '@/views/components/ColorWithOpacitySelector'
    import TooltipWithImage from '@/views/components/TooltipWithImage'

    export default {
        components: {
            TooltipWithImage,
            ColorWithOpacitySelector,
            BackgroundSelector,
            BFormGroup,
            BFormInput,
            BFormCheckbox,
            BRow,
            BCol,
            vSelect
        },
        data() {
            return {

                config: {
                    background: {},
                    cards: {
                        card_shadows: true,
                        active_card_override: false,
                        card_type: 0,
                        lane: {
                            background: {},
                            position: 0.5
                        },
                        normal: {
                            background_type: 0,
                            name: {
                                color: '#f1f1f1',
                                opacity: 1.0
                            },
                            background: {},
                            secondary_background: {}
                        },
                        active: {
                            background_type: 0,
                            name: {
                                color: '#f1f1f1',
                                opacity: 1.0
                            },
                            background: {},
                            secondary_background: {}
                        }
                    }
                },

                cardTypes: [
                    {
                        id: 0,
                        name: 'Icon and name'
                    },
                    {
                        id: 1,
                        name: 'Only Icon'
                    },
                    {
                        id: 2,
                        name: 'Only Name'
                    }
                ],

                backgroundTypes: [
                    {
                        id: 0,
                        name: 'In one piece'
                    },
                    {
                        id: 1,
                        name: 'Separate for icon and name'
                    }
                ]

            }
        },
        watch: {
            'config.cards.card_type'() {
                if (this.config.cards.card_type !== 0 && (this.config.cards.normal.background_type !== 0 || this.config.cards.active.background_type !== 0)) {
                    this.config.cards.normal.background_type = 0
                    this.config.cards.active.background_type = 0
                }
            }
        },
        methods: {
            getConfig() {
                return this.config
            },
            setConfig(config) {
                this.config = {...this.config, ...config}
            },
            valid() {
                if (this.config.cards.normal.background_type === 1 && !this.$refs.BackgroundSelectorTextBackground.valid()) return false
                else if (this.config.cards.active.background_type === 1 && !this.$refs.BackgroundSelectorActiveTextBackground.valid()) return false
                else if (!this.$refs.BackgroundSelectorBackground.valid()) return false
                else if (!this.$refs.BackgroundSelectorCards.valid()) return false
                else if (!this.$refs.BackgroundSelectorActiveCards.valid()) return false
                else if  (!this.$refs.BackgroundSelectorCardsLane.valid()) return false
                return true
            }
        }

    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
